(function ($) {
  $('.wheel').each(function() {
    var container = $(this);
    var which = "";

    $(this).find(".section").on("click", function() {
      which = $(this).attr('class').split(' ')[1].split('--')[1];

      container.toggleClass('open open--video'+which);
      $(".home, .back-to-menu, .play-video").toggleClass('open');
      $(".category--"+which).toggleClass('open');

      if(!container.hasClass('open')) {
        close();
      } else {
        setTimeout(function() {
          $(".category.open").find("video").addClass('played');
          $(".category.open").find("video")[0].play();
          $(".play-video").addClass('played');
          $(".wheel").addClass('hide');
        }, 1000);
      }
    });

    function close() {
      container.removeClass('open open--video'+which);
      $(".home, .back-to-menu, .play-video").removeClass('open');
      $(".category--"+which).removeClass('open');
      $(".wheel").removeClass('hide');

      setTimeout(function() {
        if($(".played").length) {
          $(".played").removeClass('paused');
          $(".play-video").removeClass('played');
          $(".played")[0].load();
          $(".played").removeClass("played");
        }
      }, 1000);
    }

    $(".back-to-menu").on("click", function(e) {
      e.preventDefault();
      close();
    });
  });
})(jQuery);