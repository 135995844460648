(function ($) {
  $('.play-video').each(function() {
    function controls(video) {
      video.addClass('played');
      
      if (video[0].paused) {
        video[0].play();
        video.removeClass('paused');
        $(".play-video").addClass('played');
        $(".wheel").addClass('hide');
      } else {
        video[0].pause();
        video.addClass('paused');
        $(".play-video").removeClass('played');
        $(".wheel").removeClass('hide');
      }
    }

    $(".play-video").on("click", function(e) {
      e.preventDefault();
      var video = $(".category.open").find("video");

      controls(video);
    });

    $("video").on("click", function(e) {
      e.preventDefault();
      var video = $(this);
      
      controls(video);
    });
  });
})(jQuery);