(function ($) {
  $('.calendly').each(function() {
    var src = $(".calendly iframe").attr("src");
    $(".show-calendly").on("click", function(e) {
      e.preventDefault();

      $(".calendly iframe").attr("src", src);
      $(".calendly").addClass('open');
      $(".contact").addClass('calendly-open');
    });

    $(".calendly .close").on("click", function(e) {
      e.preventDefault();

      $(".calendly").removeClass('open');
      $(".contact").removeClass('calendly-open');
    });
  });
})(jQuery);