(function ($) {
  $('.contact').each(function() {
    $(".show-form").on("click", function(e) {
      e.preventDefault();

      $(".contact").addClass('open');
    });

    $(".contact .close").on("click", function(e) {
      e.preventDefault();

      $(".contact").removeClass('open');
      $(".contact form").find('input[type="text"], input[type="email"]').val("");
      $(".contact .message").fadeOut();
      $(".contact form").fadeIn();
    });

    $(this).find("form").on("submit", function(e) {
      e.preventDefault();

      var postData = $(this).serializeArray();
      var formURL = "send.php";
      $.ajax(
      {
        url : formURL,
        type: "POST",
        data : postData,
        success:function(response, textStatus, jqXHR)
        {
          $(".contact form").fadeOut();
          $(".contact .message").fadeIn();
        },
        error: function(jqXHR, textStatus, errorThrown)
        {
          console.log("not sent");
        }
      });
    });
  });
})(jQuery);
